import React from 'react';
import styled from 'styled-components';
import { useState } from 'react';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #eaeaea;
`;

const Title = styled.h1`
  font-size: 2rem;
  color: #333;
`;

const Page1 = () => {
  return (
    <Container>
      <Title>Страница 1</Title>
      <div className='button'>
        <MyButton />
        <MyButton />
        </div>
    </Container>
  );
};

function MyButton() {
  const [count, setCount] = useState(0);

  function handleClick() {
    setCount(count + 1);
  }

  return (
    <button onClick={handleClick}>
      Жмакнуто {count} раз!
    </button>
  );
}

export default Page1;
