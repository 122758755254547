import React from 'react';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import styled from 'styled-components';
import Page1 from './pages/Page1';
import './styles.css';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  text-align: center;
`;

const Title = styled.h1`
  font-size: 3rem;
  color: #f1f1f1;
`;

const Navbar = styled.nav`
  background-color: #2b2b2b;
  padding: 1rem;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-around;
`;

const NavLink = styled(Link)`
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }
`;

function App() {
  return (
    <Router>
      <Navbar>
        <NavLink to="/">Главная</NavLink>
        <NavLink to="/page1">Не главная</NavLink>
      </Navbar>

      <Container>
        <Routes>
          <Route path="/" element={<Title>ЖОПА</Title>} />
          <Route path="/page1" element={<Page1 />} />
        </Routes>
      </Container>
    </Router>
  );
}

export default App;
